$(document).ready(function () {
  let organizer_settings_ajax_lock = false;

  $(document).on("submit", "#organizer_settings_form", function () {
    if (!organizer_settings_ajax_lock) {
      organizer_settings_ajax_lock = true;

      let formData = new FormData(
        document.getElementById("organizer_settings_form")
      );

      $.ajax({
        type: "POST",
        url: "/ajax/organizer_settings/update",
        data: formData,
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert(
              "#div_organizer_settings_button",
              data.message,
              "success",
              null,
              "#organizer_settings_form"
            );

            setTimeout(function () {
              document.location.href = "/organizer/settings";
            }, 1500);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#div_organizer_settings_button",
              data.responseJSON.error_message,
              "danger",
              null,
              "#organizer_settings_form"
            );
          }

          organizer_settings_ajax_lock = false;
        })
        .always(function () {
          organizer_settings_ajax_lock = false;
        });
    }

    return false;
  });

  if ($(".tinymce").length > 0) {
    let language = $("body").attr("lang");
    let vendorCss = "/public/static/" + $("body").data("vendor-css");

    let tinyMceVariables = {
      selector: "#email-content-tinymce",
      plugins: "link",
      toolbar: "bold link",
      menubar: false,
      statusbar: false,
      branding: false,
      valid_elements: "a[href|target],strong/b",
      invalid_elements: "*",
      forced_root_block: false,
      paste_as_text: true,
      convert_urls: false,
      content_css: vendorCss,
      content_style: ".mce-content-body {font-size:14px;}",
    };

    if (language === "no") {
      tinyMceVariables.language = "nb_NO";
    }

    tinymce.init(tinyMceVariables);
  }
});
