$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p === 'organizer_settings') {

            $(".organizer-translations-organizer-about-button").on("click", function (e) {
                e.preventDefault();
                let language = $(this).attr('data-language');

                if ($(".organizer-translations-organizer-about-wrapper[data-language='" + language +"']").hasClass('d-none')){
                    $(".organizer-translations-organizer-about-wrapper[data-language='" + language +"']").removeClass('d-none');
                }

            });

        }


        if (p === 'organizer_event') {

            $(".organizer-translations-event-name-button").on("click", function (e) {
                e.preventDefault();
                let language = $(this).attr('data-language');

                if ($(".organizer-translations-event-name-wrapper[data-language='" + language +"']").hasClass('d-none')){
                    $(".organizer-translations-event-name-wrapper[data-language='" + language +"']").removeClass('d-none');
                }

            });

            $(".organizer-translations-event-description-button").on("click", function (e) {
                e.preventDefault();
                let language = $(this).attr('data-language');

                if ($(".organizer-translations-event-description-wrapper[data-language='" + language +"']").hasClass('d-none')){
                    $(".organizer-translations-event-description-wrapper[data-language='" + language +"']").removeClass('d-none');
                }

            });
        }

        if (p === 'organizer_event_ticket_category') {

            $(".organizer-translations-event-ticket-category-name-button").on("click", function (e) {
                e.preventDefault();
                let language = $(this).attr('data-language');

                if ($(".organizer-translations-event-ticket-category-name-wrapper[data-language='" + language +"']").hasClass('d-none')){
                    $(".organizer-translations-event-ticket-category-name-wrapper[data-language='" + language +"']").removeClass('d-none');
                }

            });

            $(".organizer-translations-event-ticket-category-description-button").on("click", function (e) {
                e.preventDefault();
                let language = $(this).attr('data-language');

                if ($(".organizer-translations-event-ticket-category-description-wrapper[data-language='" + language +"']").hasClass('d-none')){
                    $(".organizer-translations-event-ticket-category-description-wrapper[data-language='" + language +"']").removeClass('d-none');
                }

            });
        }


    }
    );